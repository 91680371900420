<template>
  <XDialog
      :buttons="buttons" :text="text" :title="title" :value="value" :width="width" @input="$emit('input', $event)">
    <template #dialog-content>
      <slot/>
    </template>
  </XDialog>
</template>

<script>
import XDialog from '@/components/basic/XDialog';

export default {
  name: 'OkDialog',
  components: {XDialog},
  props: {
    value: Boolean,
    title: String,
    text: String,
    width: {
      Number,
      String,
    },
  },
  data() {
    return {
      buttons: [
        {
          text: 'Ok',
          color: 'primary',
          click: () => this.$emit('input', false),
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>